import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';

interface CoerceFunction<Value = string> {
  (value: string): Value
}

interface QueryParams<Value = any> { // eslint-disable-line @typescript-eslint/no-explicit-any
  [key: string]: Value
}

export function paramsToQueryString(params: QueryParams = {}): string {
  const query = Object.entries(params).map(([key, value]) => `${key}=${value}`).join('&');
  return query ? `?${query}` : '';
}

export function queryStringToParams<Value = string>(query = '', coerce?: CoerceFunction<Value>): QueryParams<Value> {
  if (!query) {
    return {};
  }
  if (query[0] === '?') {
    query = query.slice(1);
  }
  return query.split('&').reduce((params, keyval) => {
    let [key, value = 'true'] = keyval.split('=');
    // decode each side after split because either could contain =
    key = decodeURIComponent(key);
    value = decodeURIComponent(value);
    return { ...params, [key]: coerce ? coerce(value) : value };
  }, {});
}

export function getUrlQueryParams(): { [key: string]: string } {
  return queryStringToParams(window.location.hash.split('?')[1]);
}

export function getSameOriginUrl(href: string): string {
  // rewrite urls to use the same origin
  const treatedUrl = new URL(window.location.href);
  const linkUrl = new URL(href, window.location.href);
  treatedUrl.hash = linkUrl.hash;
  return treatedUrl.toString();
}

export function deconstructUrl(url: string): { url: string, params: { [key: string]: string } } {
  const [base, search] = url.split('?');
  return { url: base, params: queryStringToParams(search) };
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export function omitEmptyParams<T extends unknown>(params: Record<string, T>): Record<string, T> {
  return omitBy(params, value => isNil(value) || value === '');
}

/**
 * Take a serializable object and convert it to a single URL parameter string
 * Uses a dot notation for nested objects and arrays
 * NOTE: This function may not support nested arrays
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function objectToUrlParam(obj: any, pathPrefix = ''): string {
  let paramString = '';
  for (const [key, value] of Object.entries(obj)) {
    if (!value) {
      continue;
    }
    // ensure pathPrefix ends with a dot
    pathPrefix = !pathPrefix || pathPrefix.endsWith('.') ? pathPrefix : `${pathPrefix}.`;
    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        const parts = [];
        for (const arrayValue of value) {
          if (typeof arrayValue === 'object') {
            parts.push(objectToUrlParam(arrayValue, `${pathPrefix}${key}`));
          } else {
            parts.push(arrayValue);
          }
        }
        if (parts.length > 0) {
          paramString += `${pathPrefix}${key}=${parts.join(',')};`;
        }
      }
      else {
        // don't add a semi here, sub keys will do that
        paramString += objectToUrlParam(value, `${pathPrefix}${key}`);
      }
    } else {
      paramString += `${pathPrefix}${key}=${value};`;
    }
  }
  return paramString;
}

/**
 * Take a single URL parameter string and convert it to an object
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function urlParamToObject(urlParam: string): any {
  // NOTE: This function may not support nested objects
  if (!urlParam) {
    return {};
  }
  const castValue = (value: string): string | number | boolean => {
    if (value === 'true') {
      return true;
    }
    else if (value === 'false') {
      return false;
    }
    else if (!isNaN(Number(value))) {
      return Number(value);
    }
    return value;
  };
  const params = {};
  const parts = urlParam.split(';');
  for (const part of parts) {
    if (part) {
      const [key, value] = part.split('=');
      if (key && value) {
        const keys = key.split('.');
        let current = params;
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];
          if (i === keys.length - 1) {
            if (value.includes(',')) {
              const values = value.split(',');
              const typedValues = values.map(castValue);
              current[key] = typedValues;
            }
            else {
              current[key] = castValue(value);
            }
          } else {
            current[key] = current[key] || {};
            current = current[key];
          }
        }
      }
    }
  }
  return params;
}
