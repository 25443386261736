import createAuth0Client from '@auth0/auth0-spa-js';

import { ApiConfig } from './src/shared/models/api-config.model';
import { queryStringToParams } from './src/shared/url.utils';

tryConfigUrls(
  '/api/config',
  (window.location.pathname + '/api/config').replace(/\/\//g, '/'),
).then(async apiConfig => {
  (window as any).flywheelApiConfig = apiConfig;
  const auth0Config = apiConfig.auth.auth0;
  const auth0Enabled = isAuth0Enabled(apiConfig);
  const token = localStorage.getItem('satellizer_token');
  const queryParams = queryStringToParams(location.search);
  if (token && !location.hash) {
    // skip the login page if we have a token
    location.hash = '#/projects';
  }
  if (!auth0Enabled || token || queryParams.code || queryParams.error) {
    // bootstrap the app
    return loadMainApplication();
  }
  // log in with auth0
  const auth0 = await createAuth0Client({
    domain: auth0Config.domain,
    client_id: auth0Config.client_id,
    audience: auth0Config.audience,
  });
  if (window.self !== window.top && queryParams.redirect_url) {
    // handle auth from within an iframe and avoid CSP
    return auth0.loginWithPopup({
      connection: queryParams.auth0_direct_connection_name,
    })
      .then(() => auth0.getTokenSilently())
      .then(token => {
        localStorage.setItem('satellizer_token', `Bearer ${token}`);
        window.location.href = queryParams.redirect_url;
      });
  }
  // don't redirect to logout after logging in
  const redirectUri = window.location.hash === "#/logout" ? window.location.origin : window.location.href;
  return auth0.loginWithRedirect({
    redirect_uri: redirectUri,
    connection: queryParams.auth0_direct_connection_name,
  });
}).catch(error => {
  (window as any).flywheelApiConfig = {
    error: error,
    auth: {},
    features: {},
    site: {},
  };
  // let the main app handle any error
  loadMainApplication();
});

function loadMainApplication() {
  import(/* webpackChunkName: "index" */'./index');
}

function isAuth0Enabled(apiConfig: ApiConfig): boolean {
  if (!apiConfig.auth.auth0) {
    return false;
  }
  if (location.search.includes('auth0=false')) {
    return false;
  }
  if (location.search.includes('auth0=true')) {
    return true;
  }
  if (typeof apiConfig.features.auth0 === 'boolean') {
    return apiConfig.features.auth0;
  }
  return true;
}

async function tryConfigUrls(...configUrls: string[]) {
  for (const url of configUrls) {
    const json = await fetch(url).then(async response => {
      if (response.status === 404) {
        return undefined;
      }
      const data = await response.json();
      if (!response.ok) {
        throw new Error(`HTTP ${response.status} ${response.url}: ${data.message}`);
      }
      return data;
    });
    if (json) {
      if (url !== '/api/config') {
        json.api_base = url.replace(/\/api\/config$/, '');
      }
      return json;
    }
  }
  throw new Error('API config not found');
}
